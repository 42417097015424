import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Pagination,
  Form,
} from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import RenderModalConfirm from "../../../fyb_components/results/Modals/RenderModalConfirm";
import RenderButtonMore from "../../../fyb_components/results/RenderButtonMore";
import { useForm } from "react-hook-form";
import RenderInputCancel from "../../../fyb_components/results/RenderInputCancel";
import RenderCircle from "../../../fyb_components/results/RenderCircle";

const AdminUsers = () => {
  const [users, setUsers] = useState(null);
  const [closeInput, setCloseInput] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    updateUserById(data.search);
  };

  const getUsers = async () => {
    await fletesAPI
      .get(`api/users/`)
      .then((e) => {
        setUsers(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reloadUser = async (url) => {
    await fletesAPINotURL
      .get(url)
      .then((e) => {
        setUsers(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateUserById = async (mail) => {
    await fletesAPI
      .get(`api/users/search/`, {
        params: {
          email: mail,
        },
      })
      .then((e) => {
        console.log(e.data);
        setUsers({ previous: null, next: null, results: [e.data] });
        setCloseInput(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteUser = async (id) => {
    await fletesAPI
      .delete(`api/users/${id}/`)
      .then((e) => {
        getUsers();
        toast.success("Usuario eliminado con exito");
        setModalDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!users) {
      getUsers();
    }
  }, [users]);

  const focusCompany = watch("company");

  useEffect(() => {
    if (focusCompany === undefined || focusCompany === null) {
      getUsers();
    }
  }, [focusCompany]);
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex mt-3 justify-content-between align-items-center">
            <h4 className="m-0">Todas los usuarios</h4>
            <div className="d-flex">
              <Form
                className="d-flex"
                style={{ width: "300px" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <RenderInputCancel
                  name={"search"}
                  register={register}
                  errors={errors.search && errors.search.message}
                  placeholder={"Buscar"}
                  type={"text"}
                  close={closeInput}
                  onClose={() => {
                    setCloseInput(false);
                    getUsers();
                    reset();
                  }}
                />
                <Button type="submit" className="text-white">
                  Buscar
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <hr />
        <Table striped bordered className="dashboard_main_container_table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Telefone</th>
              <th style={{ width: "90px" }}>Verificado</th>
              <th style={{ width: "90px" }}>Afiliado</th>
              <th style={{ width: "40px" }}></th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.results.map((e, i) => (
                <tr key={i}>
                  <td>
                    {e.profile.first_name} {e.profile.last_name}
                  </td>
                  <td>{e.email}</td>
                  <td>{e.profile.phone}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <RenderCircle state={e.confirmed} />
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <RenderCircle state={e.affiliate} />
                    </div>
                  </td>
                  <td>
                    <RenderButtonMore
                      buttons={[
                        {
                          label: (
                            <>
                              Editar <MdEdit />
                            </>
                          ),
                          onClick: () => {
                            window.location.href = `/dashboard/adminusers/${e.id}/edit`;
                          },
                        },
                        {
                          label: (
                            <>
                              Eliminar <MdDelete />
                            </>
                          ),
                          onClick: () => {
                            setDataSelected(e);
                            setModalDelete(true);
                          },
                        }
                      ]}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {users && (
          <Pagination>
            <Pagination.Prev
              disabled={!users.previous}
              onClick={() => reloadUser(users.previous)}
            />
            <Pagination.Next
              disabled={!users.next}
              onClick={() => reloadUser(users.next)}
            />
          </Pagination>
        )}
      </Container>
      <RenderModalConfirm
        title="Eliminar Almacen"
        state={modalDelete}
        setState={setModalDelete}
        confirmModal={() => deleteUser(dataSelected.id)}
        confirmText="¿Estas seguro de eliminar este usuario?"
      />
    </>
  );
};

export default AdminUsers;

import { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import useFetch from "../../../hooks/useFetch";
import toast, { Toaster } from "react-hot-toast";
import { colourStyles, citiesStyles } from "../../../utils/styles";
import { updateTruckValidation } from "../../../utils/formsValidation";
import { addNewOrderDefaultValues } from "../../../utils/defaultValues";
import { statusOptions } from "../../../utils/defaultValues";
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";

export default function ModalClone({
  show,
  setShow,
  getMore,
  bedTypes,
  points,
  toClone,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(updateTruckValidation),
  });

  const onSubmit = (data) => {
    for(var key in dates){
        if(data[key])
        {
            data['ready_pickup_date'] = dates[key];
            addNewOrder(data);
        }
    }
  };

  const { fletesAPI } = useFetch();
  const [dates, setDates] = useState(false);

  const addNewOrder = async (data) => {
    await fletesAPI
      .post(
        `api/trucklinepost/`,
        {
          company_id: toClone.company.id,
          status: data['status']['value'],
          origin_city_id: data['origin_city_id']['value'],
          destination_city_id: data['destination_city_id']['value'],
          point_of_contact_id: data.point_of_contact_id.value,
          bed_type_ids: data.bed_type_ids.flatMap((e) => [e.value]),
          rate: data.rate === "" ? null : data.rate,
          internal_reference_number: data.internal_reference_number,
          comments: data.comments,
          weight: data.weight === "" ? null : toClone.weight,
          ready_pickup_date: moment.utc(data.ready_pickup_date).format(),
        })
      .then(() => {
        setShow(false);
        getMore("init");
        reset(addNewOrderDefaultValues);
        toast.success(mensajesAPI("toast_success_saving_message"));
      })
      .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error(mensajesAPI("toast_generic_error"));
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };


  useEffect(() => {
    setTimeout(() => {
      if (toClone) {
        setValue("status", {
          value: toClone.status,
          label: statusOptions[toClone.status].label,
          color: statusOptions[toClone.status].color,
        });
        setValue("origin_city_id", {
          value: toClone.origin_city.id,
          label: `${toClone.origin_city.name}, ${toClone.origin_city.state.name}, ${toClone.origin_city.state.country.name}`,
        });
        setValue("destination_city_id", {
          value: toClone.destination_city.id,
          label: `${toClone.destination_city.name}, ${toClone.destination_city.state.name}, ${toClone.destination_city.state.country.name}`,
        });
        setValue("weight", toClone.weight === null ? "" : toClone.weight);
        setValue("height", toClone.height === null ? "" : toClone.height);
        setValue("length", toClone.length === null ? "" : toClone.length);
        setValue("width", toClone.width === null ? "" : toClone.width);
        setValue("point_of_contact_id", {
          value: toClone.point_of_contact.id,
          label: `${toClone.point_of_contact.first_name} ${toClone.point_of_contact.last_name}`,
        });
        setValue(
          "bed_type_ids",
          toClone.bed_type.flatMap((e) => [{ value: e.id, label: e.name }])
        );
        setValue("rate", toClone.rate === null ? "" : toClone.rate);
        setValue("comments", toClone.comments);
        setValue(
          "internal_reference_number",
          toClone.internal_reference_number
        );
        setValue("origin_zip_code", toClone.origin_zip_code);
        setValue("destination_zip_code", toClone.destination_zip_code);
        setValue("ready_pickup_date", new Date(toClone.ready_pickup_date));
        setValue(
          "number_of_pallets",
          toClone.number_of_pallets === null
            ? ""
            : toClone.number_of_pallets
        );
      }
    }, 50);
    calculateNextDays();
  }, [toClone, show]);

  function calculateNextDays(){
    var today = new Date();
    var results = {};
    for(var cont = 0; cont < 31; cont++){
        var toAddDate = "day_" + cont;
        results[toAddDate] = today.setDate(today.getDate()+1);
    }
    setDates(results);
  }

  const bedTypeOptions =
    bedTypes === null
      ? []
      : bedTypes.map((e) => ({ value: e.id, label: e.name }));

  const pointsOptions =
    points === null
      ? []
      : points.map((e) => ({
          value: e.id,
          label: `${e.first_name} ${e.last_name}`,
        }));

  const [loadOriginCities, setLoadOriginCities] = useState(false);
  const [originCities, setOriginCities] = useState(null);
  const [loadDestinationCities, setLoadDestinationCities] = useState(false);
  const [destinationCities, setDestinationCities] = useState(null);
  const resOriginCities = originCities === null ? [] : originCities.map((e) => ({ value: e.id, label: `${e.name}, ${e.state.name}, ${e.state.country.name}`}));
  const resDestinationCities = destinationCities === null ? [] : destinationCities.map((e) => ({ value: e.id, label: `${e.name}, ${e.state.name}, ${e.state.country.name}`, }));

  const updateOriginCities = async (data) => {
    await fletesAPI
      .get(`api/city/search/?name=${data}`)
      .then((e) => {
        setOriginCities(e.data);
        setLoadOriginCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getOriginCities = async () => {
    await fletesAPI
      .get(`api/city/`)
      .then((e) => {
        setOriginCities(e.data.results);
        setLoadOriginCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateDestinationCities = async (data) => {
    await fletesAPI
      .get(`api/city/search/?name=${data}`)
      .then((e) => {
        setDestinationCities(e.data);
        setLoadDestinationCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDestinationCities = async () => {
    await fletesAPI
      .get(`api/city/`)
      .then((e) => {
        setDestinationCities(e.data.results);
        setLoadDestinationCities(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateLoadOrigin = (e) => {
    setLoadOriginCities(true);
    setTimeout(() => {
      updateOriginCities(e.target.value);
    }, 400);
  };

  const updateLoadDestination = (e) => {
    setLoadDestinationCities(true);
    setTimeout(() => {
      updateDestinationCities(e.target.value);
    }, 400);
  };

  return (
    <>
      <Toaster />
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset(addNewOrderDefaultValues);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Clonar Camion</h5>
          </Modal.Header>
          <Modal.Body>
            <Row className="dashboard_main_container_form_select">
              <Form.Group className="mb-3" controlId="formCityOrgin">
                <Form.Label>Ciudad de origen</Form.Label>
                <Controller
                  name="origin_city_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isLoading={loadOriginCities}
                      styles={citiesStyles}
                      placeholder="Seleccione su ciudad de origen"
                      isDisabled
                      {...field}
                      options={resOriginCities}
                      onFocus={() => {
                        getOriginCities();
                        setLoadOriginCities(true);
                      }}
                      onKeyDown={(e) => {
                        e.target.value
                          ? e.target.value.length > 2
                            ? updateLoadOrigin(e)
                            : setTimeout(() => {
                                getOriginCities();
                              }, 50)
                          : setTimeout(() => {
                              getOriginCities();
                            }, 50);
                      }}
                    />
                  )}
                />
               { errors.origin_city_id && <p className="dashboard_form_error_message">{mensajesFormulario("city_required")}</p> }
              </Form.Group>
            </Row>
            <Row className="dashboard_main_container_form_select">
              <Form.Group className="mb-3" controlId="formCityDestiny">
                <Form.Label>Ciudad de destino</Form.Label>
                <Controller
                  name="destination_city_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isLoading={loadDestinationCities}
                      styles={citiesStyles}
                      placeholder="Seleccione su ciudad de destino"
                      isDisabled
                      {...field}
                      options={resDestinationCities}
                      onFocus={() => {
                        getDestinationCities();
                        setLoadDestinationCities(true);
                      }}
                      onKeyDown={(e) => {
                        e.target.value
                          ? e.target.value.length > 2
                            ? updateLoadDestination(e)
                            : setTimeout(() => {
                                getDestinationCities();
                              }, 50)
                          : setTimeout(() => {
                              getDestinationCities();
                            }, 50);
                      }}
                    />
                  )}
                />
                { errors.destination_city_id && <p className="dashboard_form_error_message">{mensajesFormulario("city_required")}</p> }
              </Form.Group>
            </Row>
            <Row className="dashboard_main_container_form_select">
              <Form.Group className="mb-3" controlId="formLength">
                <Form.Label>Punto de contacto</Form.Label>
                <Controller
                  name="point_of_contact_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Seleccione su punto de contacto"
                      {...field}
                      options={pointsOptions}
                      styles={citiesStyles}
                    />
                  )}
                />
                { errors.point_of_contact_id && <p className="dashboard_form_error_message">{mensajesFormulario("point_of_contact_required")}</p> }
              </Form.Group>
            </Row>
            <Row className="dashboard_main_container_form_select">
              <Form.Group className="mb-3" controlId="formBedType">
                <Form.Label>Tipo de Caja Tráiler</Form.Label>
                <Controller
                  name="bed_type_ids"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Selecciona los tipos de caja tráiler"
                      isMulti
                      {...field}
                      options={bedTypeOptions}
                    />
                  )}
                />
                { errors.bed_type_ids && <p className="dashboard_form_error_message">{mensajesFormulario("bed_type_required")}</p> }
              </Form.Group>
            </Row>
            <Row>
              <Form.Label>Selecciona las Fechas de Carga</Form.Label>
              {dates &&
                Object.keys(dates).map((e, i) => {
                    return (
                        <Col xl={2} lg={2} md={2} sm={2}>
                            <Form.Check {...register(e)} isInvalid={errors.carrier} type="checkbox" label={moment(dates[e]).format("DD/MM")} />
                        </Col>
                    )
                })
               }
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formRate">
                  <Form.Label>Tarifa</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Ingresa tu tarifa"
                      {...register("rate")}
                      isInvalid={errors.rate}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col className="dashboard_main_container_form_select">
                <Form.Group className="mb-3" controlId="formStatusSelect">
                  <Form.Label>Estado</Form.Label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Status"
                        {...field}
                        styles={colourStyles}
                        defaultValue={statusOptions[0]}
                        options={statusOptions}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Form.Group className="mb-3" controlId="formLoadDetails">
                <Form.Label>Número de referencia interna</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="número de referencia interna"
                  {...register("internal_reference_number")}
                  isInvalid={errors.internal_reference_number}
                />
                { errors.internal_reference_number && <p className="dashboard_form_error_message">{mensajesFormulario("internal_reference_number_required")}</p> }
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formComments">
                <Form.Label>
                  Descripcion de carga e Instrucciones Especiales
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...register("comments")}
                  isInvalid={errors.comments}
                />
                { errors.comments && <p className="dashboard_form_error_message">{mensajesFormulario("comments_required")}</p> }
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit" size="sm">
              Guardar Cambios
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
                reset(addNewOrderDefaultValues);
              }}
            >
              Cancelar Cambios
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

import { useContext } from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerCompanyValidation } from "../../utils/formsValidation";
import useFetch from "../../hooks/useFetch";
import { BodegasContext } from "../../context";
import { sessionType, companyIdType } from '../../context/reducers'

export default function ModalSelectCompany({ show, setShow, companies, updateName }) {
  const { handleSubmit, reset, control, setValue } = useForm({
    resolver: yupResolver(registerCompanyValidation),
  });
  const { dispSession, dispCompanyId, companyId } = useContext(BodegasContext);
  const { fletesAPI } = useFetch()
  const registerCompany = async (com) => {
    await fletesAPI
      .get(`api/company/${com}/switch/`)
      .then((e) => {
        dispSession({type: sessionType.setSession, session: e.data.session});
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onSubmit = async (data) => {
    setShow(false);
    dispCompanyId({type: companyIdType.setCompanyId ,companyId: data.company.value});
    await registerCompany(data.company.value);
    await updateName()    
    reset();
    window.location.reload();
  };
  const options = companies === null ? [] : companies.flatMap((e) => [{ value: e.company.id, label: e.company.name }]);
  const defaultValue = companies === null ? {} : companies.find((e) => e.company.id === companyId);
  if (companies) {
    setValue("company", {
      value: defaultValue.company.id,
      label: defaultValue.company.name,
    });
  }
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Selecciona la compañia</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nombre</Form.Label>
                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Selecciona la empresa"
                      {...field}
                      options={options}
                      isClearable
                    />
                  )}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit" size="sm">
              Ir a compañia
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

import { useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import moment from "moment";

import useFetch from "../../../../hooks/useFetch";
import { erroresCodes, fullNameCity } from "../../../../utils";
import RenderSelect from "../../../../fyb_components/results/RenderSelect";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import SelectEnableTruck from "../../../../fyb_components/results/SelectEnableTrucks";
import SelectQouteCity from "../../../../fyb_components/results/SelectQouteCity";
import SelectDate from "../../../../fyb_components/results/Selectdate";


const prepareData = (data) => ({
  origin_city: data.origin_city.value,
  destination_city: data.destination_city.value,
  pickup_time: moment.utc(data.pickup_time).format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
  delivery_time: moment.utc(data.delivery_time).format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
  currency: data.currency.value,
  expiration: moment.utc(data.expiration).format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
  cargo: data.cargo,
  weight: data.weight,
  pallets: data.pallets,
  bed_type_id: data.bed_type_id.value,
  total: data.total,
  extras: "",
})

const EditQoute = ({ quote, getQoute }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const { fletesAPI } = useFetch();

  const updateQoute = async (data) => {
    await fletesAPI
      .patch(`api/quote/${quote.id}/`, data)
      .then((e) => {
        console.log(e.data);
        toast.success("Qoute updated", 800);
        getQoute();
      })
      .catch((e) => {
        toast.error(erroresCodes(e.response.data.error_code), 800);
      });
  };

  const onSubmit = (data) => {
    const newData = prepareData(data);
    updateQoute(newData);
  };

  useEffect(() => {
    if (Boolean(quote)) {
      setValue("bed_type_id", {
        value: quote.bed_type.id,
        label: quote.bed_type.name,
      });
      setValue("destination_city", {
        value: quote.destination_city.id,
        label: fullNameCity(quote.destination_city),
      });
      setValue("origin_city", {
        value: quote.origin_city.id,
        label: fullNameCity(quote.origin_city),
      });

      setValue("pickup_time", new Date(quote.pickup_time));

      setValue("delivery_time", new Date(quote.delivery_time));
      setValue("total", quote.total);
      setValue("currency", {
        value: quote.currency,
        label: quote.currency, 
      });
      setValue("distance", quote.distance);
      setValue("expiration", new Date(quote.expiration));

      setValue("cargo", quote.cargo);
      setValue("weight", quote.weight);
      setValue("pallets", quote.pallets);
    }
  }, [quote]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button variant="primary" className="text-white" type="submit">
            Guardar cambios
          </Button>
        </Col>
      </Row>
      <hr />
      <Row className="d-flex flex-column gap-4">
        <Row>
          <SelectEnableTruck
            name="bed_type_id"
            label={"Camión"}
            control={control}
            placeholder="Selecciona un camión"
            error={errors.truck}
          />
        </Row>
        <Row>
          <SelectQouteCity
            name="origin_city"
            control={control}
            placeholder="Origen"
            label="Origen"
            url="origin_cities"
          />
        </Row>
        <Row>
          <SelectQouteCity
            name="destination_city"
            control={control}
            placeholder="Destino"
            error={errors.destination_city}
            label="Destino"
            url="destination_cities"
          />
        </Row>

        <Row>
          <Col>
            <SelectDate
              name="pickup_time"
              control={control}
              placeholder="Selecciona la fecha de carga"
              label="Fecha de carga"
            />
          </Col>
          <Col>
            <SelectDate
              name="delivery_time"
              control={control}
              placeholder="Selecciona la fecha de entrega"
              label="Fecha de entrega"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderInput
              name="total"
              label="Total"
              register={register}
              placeholder="Total"
              error={errors.total}
            />
          </Col>
          <Col>
            <RenderSelect
              name="currency"
              label="Moneda"
              control={control}
              options={[
                { value: "USD", label: "USD" },
                { value: "MXN", label: "MXN" },
              ]
              }
              placeholder="Moneda"
              error={errors.currency}
            />
          </Col>
          <Col>
            <SelectDate
              name="expiration"
              control={control}
              placeholder="Selecciona la fecha de expiración"
              label="Fecha de expiración"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderInput
              name="cargo"
              label="Producto"
              register={register}
              placeholder="Producto"
              error={errors.distance}
            />
          </Col>
          <Col>
            <RenderInput
              name="weight"
              label="Peso"
              register={register}
              placeholder="Peso"
              error={errors.weight}
            />
          </Col>
          <Col>
            <RenderInput
              name="pallets"
              label="Tarimas"
              register={register}
              placeholder="Tarimas"
              error={errors.pallets}
            />
          </Col>
        </Row>
      </Row>
    </Form>
  );
};

export default EditQoute;

import { useState } from "react";
import { Row, Col, Table, Pagination } from "react-bootstrap";
import { IoLogoWhatsapp, IoIosMail } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import moment from "moment";

import ModalContact from "./ModalContact";
import RenderPickupDate from "../../../fyb_components/results/RenderPickupDate";
import RenderCityResult from "../../../fyb_components/results/RenderCityResult";
import RenderRate from "../../../fyb_components/results/RenderRate";
import ModalDelete from "./ModalDelete";
import { FaPallet } from "react-icons/fa";

const MyQuotesTable = ({ quotes, isMobile, reloadQoutes }) => {
  const [showDel, setShowDel] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);

  const [modalContact, setModalContact] = useState({
    show: false,
    data: null,
  });
  const showModalContact = (e) => {
    setModalContact({ show: true, data: e });
  };

  const hideModalContact = () => {
    setModalContact({ show: false, data: null });
  };

  return (
    <>
      <Row>
        <Col>
          {quotes && quotes.results.length > 0 ? (
            <>
              <Table
                striped
                bordered
                className="dashboard_main_container_table"
              >
                <thead>
                  <tr>
                    <th>Expiracion</th>
                    <th>No. Referencía</th>
                    <th>Transportista</th>
                    <th>Ciudad de origen</th>
                    <th>Ciudad de destino</th>
                    <th>Costo </th>
                    <th>Distacia </th>
                    <th>Hora de Recolecion </th>
                    <th>Hora de Entrega </th>
                    <th>Contactar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {quotes &&
                    quotes.results.map((e, i) => {
                      return (
                        <tr key={i}>
                          <RenderPickupDate
                            date={e.expiration}
                            isMobile={isMobile}
                          />
                          <td>
                            <p> {e.reference} </p>
                          </td>
                          <td>
                            <p
                              className="link"
                              onClick={() => showModalContact(e)}
                            >
                              {e.transportation_company.name}
                            </p>
                          </td>
                          <RenderCityResult
                            origin_city={e.origin_city}
                            destination_city={e.destination_city}
                            display_city={e.origin_city}
                            isMobile={isMobile}
                            subtitle={"Origen"}
                          />
                          <RenderCityResult
                            origin_city={e.origin_city}
                            destination_city={e.destination_city}
                            display_city={e.destination_city}
                            isMobile={isMobile}
                            subtitle={"Destino"}
                          />
                          <RenderRate
                            rate={e.total}
                            isMobile={isMobile}
                            currency={e.currency}
                          />
                          <td>
                            <p> {e.distance} km </p>
                          </td>
                          <td>
                            <p>
                              {" "}
                              {moment(e.pickup_time).format(
                                "DD/MM/YYYY HH:MM"
                              )}{" "}
                            </p>
                          </td>
                          <td>
                            <p>
                              {" "}
                              {moment(e.delivery_time).format(
                                "DD/MM/YYYY HH:MM"
                              )}{" "}
                            </p>
                          </td>
                          <td>
                            <div className="contact-td">
                              {e?.transportation_company?.contact_information
                                ?.phone && (
                                <a
                                  href={`https://wa.me/${e?.transportation_company?.contact_information?.phone}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <IoLogoWhatsapp />
                                </a>
                              )}
                              {e?.transportation_company?.contact_information
                                ?.phone && (
                                <a
                                  href={`tel:${e?.transportation_company?.contact_information?.phone}`}
                                >
                                  <FaPhone />
                                </a>
                              )}
                              {e?.transportation_company?.contact_information
                                ?.mail && (
                                <a
                                  href={`mailto:${e?.transportation_company?.contact_information?.mail}`}
                                >
                                  <IoIosMail />
                                </a>
                              )}
                            </div>
                          </td>
                          <td>
                              {e.freight_id ?
                                  (
                                    <><a href={`/dashboard/my_order/${e.freight_id}/edit`}><FaPallet /></a></>
                                  )
                                  :
                                  (<>
                                      <div className="button-delete">
                                          <MdDelete
                                            onClick={() => {
                                              setShowDel(true);
                                              setDataDelete(e);
                                            }}
                                          />
                                        </div>
                                      </>)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {quotes && (
                <Pagination>
                  <Pagination.Prev
                    disabled={!quotes.previous}
                    onClick={() => reloadQoutes(quotes.previous)}
                  />
                  <Pagination.Next
                    disabled={!quotes.next}
                    onClick={() => reloadQoutes(quotes.next)}
                  />
                </Pagination>
              )}
            </>
          ) : (
            <>
              <br />
              <h3 className="text-center fyb_h2">No tienes estimaciones</h3>
            </>
          )}
        </Col>
      </Row>
      <ModalContact data={modalContact} hide={hideModalContact} />
      <ModalDelete
        show={showDel}
        setShow={setShowDel}
        dataDelete={dataDelete}
        getMore={reloadQoutes}
      />
    </>
  );
};

export default MyQuotesTable;

import { useContext } from "react";
import { Modal, Button, Form, Row, } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerCompanyValidation } from "../../utils/formsValidation";
import useFetch from "../../hooks/useFetch";
import { BodegasContext } from "../../context";
import { sessionType, companyIdType, userIdType } from '../../context/reducers'

export default function ModalSelectCompany({ show, setShow, companies }) {
  const {
    handleSubmit,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(registerCompanyValidation),
  });
  const { dispSession, dispCompanyId, dispUserId } = useContext(BodegasContext);
  const { fletesAPI } = useFetch()
  const registerCompany = async (com) => {
    await fletesAPI
      .get(`api/company/${com}/switch/`)
      .then((e) => {
        console.log(e);
        dispSession({type: sessionType.setSession, session: e.data.session});
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onSubmit = (data) => {
    setShow(false);
    dispCompanyId({type: companyIdType.setCompanyId ,companyId: data.company.value});
    registerCompany(data.company.value);
    reset();
  };
  const options = companies === null ? [] : companies.flatMap((e) => [{value: e.company.id, label: e.company.name}])
  return (
    <>
      <Modal show={show}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <h5 className="fyb_h5 text-center">Seleccionar tu compañia</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nombre</Form.Label>
                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Selecciona la compañia"
                      {...field}
                      options={options}
                      isClearable
                    />
                  )}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit" size="sm">
              Ir a compañia
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                dispSession({type: sessionType.delSession});
                dispUserId({type: userIdType.delUserId});
                window.location.href = "/login";
              }}
            >
              Cerrar Sesión
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

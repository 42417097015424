import { useContext } from "react";
import { BodegasContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import RenderInput from "../../../fyb_components/results/RenderInput";
import { useForm } from "react-hook-form";

const statusOption = [
  { value: "1", label: "Activo" },
  { value: "0", label: "Inactivo" },
];

const ModalAddWarehouse = ({ state, setState, getMore }) => {
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    createTruck(data);
  };

  const handleClose = () => {
    setState(false);
    reset();
  };

  const createTruck = async (data_fetech) => {
    await fletesAPI
      .post("api/companytruck/", {...data_fetech, company_id: companyId})
      .then((e) => {
        toast.success("Bodega creada con exito");
        setState(false);
        reset();
        getMore();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Toaster />
      <Modal show={state} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              Agregar Camion
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RenderInput
              name={"number"}
              label="Numero del camion"
              placeholder="ingrese el numero del camion"
              register={register}
              error={errors.number && errors.number.message}
            />
            <RenderInput
              name={"license_plate"}
              label="Placa del camion"
              placeholder="ingrese la placa del camion"
              register={register}
              error={errors.license_plate && errors.license_plate.message}
            />
            <RenderInput
              name={"vin"}
              label="VIN"
              placeholder="ingrese el VIN"
              register={register}
              error={errors.vin && errors.vin.message}
            />
            <RenderInput
              name={"brand"}
              label="Marca"
              placeholder="ingrese la marca"
              register={register}
              error={errors.brand && errors.brand.message}
            />
            <RenderInput
              name={"model"}
              label="Modelo"
              placeholder="ingrese el modelo"
              register={register}
              error={errors.model && errors.model.message}
            />
            <RenderInput
              name={"year"}
              label="Año"
              placeholder="ingrese el año"
              register={register}
              error={errors.year && errors.year.message}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Guardar
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAddWarehouse;

import React, { useState, useEffect } from "react";
import { Row, Form, FormControl, Button, Col, InputGroup, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useFetch from "../../../hooks/useFetch";
import toast from "react-hot-toast";
import SelectCity from "../SlectCity";
import BedCards from "../BedCards";

const searchValidation = yup.object().shape({
  bed_type_id: yup.object().required("Selecciona un camión").nullable(),
  origin_city_id: yup.object().required("La ciudad de origen es obligatoria").nullable(),
  destination_city_id: yup.object().required("La ciudad de destino es obligatoria").nullable(),
  pickup_time: yup.string().required("La hora de recogida es obligatoria"),
  pallets: yup.string().required("El número de tarimas es obligatorio"),
  weight: yup.string().required("El peso es obligatorio"),
});

export default function FormSelectInfo({ defaultData, next }) {
  const [bedTypes, setBedTypes] = useState([]);
  const [disables, setDisables] = useState({
    origin: true,
    destination: true,
  });
  
    const { fletesAPI } = useFetch();
    const {
      handleSubmit,
      control,
      formState: { errors },
      setValue,
      watch,
    } = useForm({
      resolver: yupResolver(searchValidation),
    });

    
    const bedType = watch("bed_type_id");
    const originCity = watch("origin_city_id");
    const destinationCity = watch("destination_city_id");

    const bedChange = (bed) => {
      if (Boolean(bed)) {
        setDisables({ ...disables, origin: false, destination: true  });
        setValue("origin_city_id", null);
        setValue("destination_city_id", null);
      } else {
        setDisables({ ...disables, origin: true, destination: true });
        setValue("origin_city_id", null);
        setValue("destination_city_id", null);
      }
    }

    const originChange = (origin) => {
      if (Boolean(origin)) {
        setDisables({ ...disables, destination: false });
        setValue("destination_city_id", null);
      } else {
        setDisables({ ...disables, destination: true });
        setValue("destination_city_id", null);
      }
    }

  const getBedTypes = async () => {
    await fletesAPI
      .get(`api/quote/available_beds/`)
      .then((e) => {
        setBedTypes(e.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const bedURL = Boolean(bedType) ? `bed_type_id=${bedType.id}&` : "";
  const originURL = Boolean(originCity) ? `origin_city_id=${originCity.value}&` : "";

  const onSubmit = (data) => {
    next(data);
  };

  useEffect(() => {
    if (bedTypes.length === 0) {
      getBedTypes();
    }
  }, [bedTypes]);

  useEffect(() => {
    const keys = Object.keys(errors);
    if (Boolean(keys.length !== 0)) {
      toast.error(errors[keys[0]].message, 2000);
    }
  }, [errors]);

  useEffect(() => {
    if (defaultData) {
      setValue("pickup_time", defaultData?.pickup_time ? new Date(defaultData?.pickup_time) : "");
      setValue(
        "delivery_time",
        defaultData?.delivery_time ? new Date(defaultData?.delivery_time) : ""
      );
      setValue("pallets", defaultData?.pallets ? defaultData?.pallets : "");
      setValue("weight", defaultData?.weight ? defaultData?.weight : "");
      setValue("cargo", defaultData?.cargo ? defaultData?.cargo : "");
    }
  }, [defaultData]);

  useEffect(() => {
    bedChange(bedType)
  }, [bedType]);
  useEffect(() => {
    originChange(originCity);
  }, [originCity]);
  useEffect(() => {
    console.log({ destinationCity });
  }, [destinationCity]);

  return (
    <>
      <Container>
        <Form className="w-100 d-flex flex-column gap-4" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Controller
              control={control}
              name="bed_type_id"
              render={({ field }) => (
                <BedCards
                  beds={bedTypes}
                  onChange={(e) => field.onChange(e)}
                  defaultValue={defaultData?.bed_type_id}
                />
              )}
            />
          </Row>
          <Row>
            <Col>
              <SelectCity
                name="origin_city_id"
                control={control}
                errors={null}
                label="Ciudad de Origen"
                placeholder="Ciudad de Origen"
                defaultValue={defaultData?.origin_city_id}
                urlAPI={`api/quote/origin_cities/?${bedURL}name=`}
                disabled={disables.origin}
              />
            </Col>
            <Col>
              <SelectCity
                name="destination_city_id"
                control={control}
                errors={null}
                label="Ciudad de Destino"
                placeholder="Ciudad de Destino"
                defaultValue={defaultData?.destination_city_id}
                urlAPI={`api/quote/destination_cities/?${bedURL}${originURL}name=`}
                disabled={disables.destination}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="date">
                <Controller
                  control={control}
                  name="pickup_time"
                  render={({ field }) => (
                    <DatePicker
                      className={`form-control`}
                      placeholderText="Selecciona fecha y hora de recolección"
                      minDate={new Date()}
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                    />
                  )}
                />
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col>
              <Controller
                control={control}
                name="pallets"
                defaultValue={""}
                render={({ field }) => (
                  <InputGroup>
                    <FormControl
                      placeholder="Tarimas"
                      aria-label="Tarimas"
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text id="basic-addon1">Uds</InputGroup.Text>
                  </InputGroup>
                )}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="weight"
                defaultValue={""}
                render={({ field }) => (
                  <InputGroup>
                    <FormControl
                      placeholder="Peso"
                      aria-label="Peso"
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text id="basic-addon1">Kg</InputGroup.Text>
                  </InputGroup>
                )}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="cargo"
                defaultValue={""}
                render={({ field }) => (
                  <InputGroup>
                    <FormControl
                      placeholder="Producto"
                      aria-label="Producto"
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                )}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Button
              type="submit"
              className="fyb_large_button text-white"
              style={{ width: "400px", fontSize: "1.2rem", fontWeight: 600 }}
            >
              Siguiente
            </Button>
          </Row>
        </Form>
      </Container>
    </>
  );
}

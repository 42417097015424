import { useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  Container,
} from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useFetch from "../../../hooks/useFetch";
import { addNewTruckRouteValidation } from "../../../utils/formsValidation";
import { truckRouteDefaultValues } from "../../../utils/defaultValues";
import toast from "react-hot-toast";
import { erroresCodes } from "../../../utils";
import { mensajesAPI } from "../../../utils/mensajes.js";
import RenderSelectCity from "../../../fyb_components/results/RenderSelectCity.jsx";

export default function ModalFormAdd({
  show,
  setShow,
  getMore,
  bedTypes,
  points,
}) {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(addNewTruckRouteValidation),
  });

  const onSubmit = (data) => {
    data.origin_city_id = data.origin_city_id.value;
    data.destination_city_id = data.destination_city_id.value;
    data.bed_type_ids = data.bed_type_ids.map((e) => e.value);
    data.base_price = data.base_price;
    data.base_currency = data.base_currency.value;

    toast.promise(addNewTruckRoute(data), {
      loading: "Guardando...",
      success: mensajesAPI("toast_success_saving_message"),
      error: mensajesAPI("toast_error_saving_message"),
    });
  };

  const { fletesAPI } = useFetch();

  const addNewTruckRoute = async (data) => {
    await fletesAPI
      .post(`api/truckroutes/`, data)
      .then(() => {
        setShow(false);
        getMore("init");
        reset(truckRouteDefaultValues);
        toast.success(mensajesAPI("toast_success_saving_message"));
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error(mensajesAPI("toast_generic_error"));
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };

  const bedTypeOptions =
    bedTypes === null
      ? []
      : bedTypes.map((e) => ({ value: e.id, label: e.name }));

  useEffect(() => {
    const keys = Object.keys(errors);
    if (Boolean(keys.length !== 0)) {
      toast.error(errors[keys[0]].message, 2000);
    }
  }, [errors]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          reset(truckRouteDefaultValues);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Añadir nueva ruta</h5>
          </Modal.Header>
          <Modal.Body>
            <Container className="gap-2 d-flex flex-column">
              <Row className="dashboard_main_container_form_select">
                <RenderSelectCity
                  name="origin_city_id"
                  control={control}
                  errors={null}
                  label="Ciudad de origen"
                  placeholder="Ingresa ciudad de origen"
                  defaultValue={null}
                />
              </Row>
              <Row className="dashboard_main_container_form_select">
                <RenderSelectCity
                  name="destination_city_id"
                  control={control}
                  errors={null}
                  label="Ciudad de destino"
                  placeholder="Ingresa ciudad de destino"
                  defaultValue={null}
                />
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="basePrice">
                    <Form.Label>Costo base</Form.Label>
                    <Controller
                      name="base_price"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <InputGroup className="m-0">
                          <InputGroup.Text>$</InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="Costo base"
                            onChange={(e) => field.onChange(e.target.value)}
                            value={field.value}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="baseCurrency">
                    <Form.Label>Moneda</Form.Label>
                    <Controller
                      name="base_currency"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Moneda"
                          {...field}
                          options={[
                            { value: "USD", label: "USD" },
                            { value: "MXN", label: "MXN" },
                          ]}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="dashboard_main_container_form_select">
                <Form.Group controlId="formBedType">
                  <Form.Label>Tipo de Caja Tráiler</Form.Label>
                  <Controller
                    name="bed_type_ids"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Selecciona los tipos de caja tráiler"
                        isClearable
                        isMulti
                        {...field}
                        options={bedTypeOptions}
                      />
                    )}
                  />
                </Form.Group>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" size="sm" type="submit">
              Crear Ruta
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
                reset(truckRouteDefaultValues);
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

import styled from "styled-components";

const Link = styled.a`
  color: ${(props) => props.cColor ? "white" : "black"};
  text-decoration: none;
  background-color: ${(props) => props.cColor};
  width: 80px;
  height: 25px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  &:hover {
    color: #000;
    text-decoration: none;
  }
`;

const RenderURL = ({ url, name, color = 'white' }) => {
  return (
    <Link cColor={color} href={url} target="_blank" rel="noreferrer">
      {name}
    </Link>
  );
}

export default RenderURL;
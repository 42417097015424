import { useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import RenderInput from "../../../../fyb_components/results/RenderInput";
import toast from "react-hot-toast";
import RenderSelectCity from "../../../../fyb_components/results/RenderSelectCity";
import { reformatCity } from "../../../../utils"

const EditContact = ({ getMore, dataCompany }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const { fletesAPI } = useFetch();

  const updateCompanyContactInformation = async (data) => {
    await fletesAPI
      .patch(`api/company/${dataCompany.id}/contact_information/`, {
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        zip_code: data.zip_code,
        phone: data.phone,
        city_id: data.city.value
      })
      .then((e) => {
        getMore();
        toast.success("Se ha actualizado la información de contacto");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = (data) => {
    updateCompanyContactInformation(data);
  };
  useEffect(() => {
    if (dataCompany !== null) {
      reset({
        address_line1: dataCompany.contact_information.address_line1,
        address_line2: dataCompany.contact_information.address_line2,
        zip_code: dataCompany.contact_information.zip_code,
        phone: dataCompany.contact_information.phone,
        city: dataCompany.contact_information.city ? reformatCity(dataCompany.contact_information.city) : undefined
      });
    }
  }, [dataCompany]);

  console.log(dataCompany);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" className="text-white" type="submit">
              Guardar Cambios
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <RenderInput
              name={"address_line1"}
              label="Dirección"
              placeholder="Ingresa tu dirección y numero"
              register={register}
              error={errors.address_line1 && errors.address_line1.message}
            />
            <RenderInput
              name={"address_line2"}
              label="Colonia"
              placeholder="Ingresa tu Colonia"
              register={register}
              error={errors.address_line2 && errors.address_line2.message}
            />
            <Form.Group controlId="city" className="mb-4">
              <Form.Label>Ciudad</Form.Label>
              <RenderSelectCity
                name={"city"}
                label="Ciudad"
                placeholder="Ingresa tu Ciudad"
                defaultValue={null}
                control={control}
                error={errors.city && "Ciudad es requerido"}
              />
            </Form.Group>
            <RenderInput
              name={"zip_code"}
              label="Código Postal"
              placeholder="Ingresa tu Código Postal"
              register={register}
              error={errors.zip_code && errors.zip_code.message}
            />
            <RenderInput
              name={"phone"}
              label="Teléfono"
              placeholder="Ingresa tu Teléfono"
              register={register}
              error={errors.phone && errors.phone.message}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditContact;

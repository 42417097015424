import React from "react";
import { Modal, Button } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import useFetch from "../../../hooks/useFetch";
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";


export default function ModalDelete({ show, setShow, dataDelete,  getMore }) {
  const  { fletesAPI } = useFetch()
  const deleteUser = async () => {
    await fletesAPI.delete(`api/company/${dataDelete.company.id}/members/`, {
        data: { member_id: dataDelete.user.id },  
      })
      .then((e) => {
        setShow(false);
        getMore("init");
        toast.success(mensajesAPI("user_success_deleted"));
      })
      .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error(mensajesAPI("toast_generic_error"));
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };
  return (
    <>
      <Toaster />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <h6>
            Estas seguro que quieres eliminar su acceso?
          </h6> 
          <p>{dataDelete && `${dataDelete.user.email}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            size="sm"
            onClick={() => deleteUser()}
          >
            Remover acceso
          </Button>
          <Button variant="danger" size="sm" onClick={() => setShow(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
import React from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import toast, { Toaster } from 'react-hot-toast';
import { mensajesAPI, mensajesFormulario } from "../../utils/mensajes.js";
import { erroresCodes } from "../../utils";

const RenderModalDelAttachmentWithToken = ({show, setShow, attachment, isMobile, getMore, token}) => {

    const { fletesAPI } = useFetch();
    const deleteAttachment = async () => {
        await fletesAPI
          .delete(`api/freightpost/${attachment.freightpost_id}/share/attachments/`, {data: {attachment_id: attachment.id, token: token}, })
          .then(() => {
            setShow(false);
            toast.success(mensajesAPI("toast_success_saving_message"));
            getMore("init");
          })
          .catch((e) => {
            if(e.request.status == 500)
            {
                toast.error(mensajesAPI("toast_generic_error"));
            }
            else
            {
                toast.error(erroresCodes(e.response.data.error_code));
            }
          });
      };

    return (
        <>
            <Toaster />
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                  <h5 className="fyb_h5 text-center">Borrar archivo</h5>
                </Modal.Header>
                {attachment && (
                  <>
                      <Modal.Body>
                          <h6>¿Estás seguro de eliminar este archivo?</h6>
                          <p>{attachment.type}: {attachment.name}</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" size="sm" onClick={() => deleteAttachment()}>
                            Eliminar
                        </Button>
                        <Button variant="danger" size="sm" onClick={() => setShow(false)}>
                            Cancelar
                        </Button>
                      </Modal.Footer>
                  </>
                )}
              </Modal>
        </>
    )
}

export default RenderModalDelAttachmentWithToken;
import { useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import useFetch from "../../../hooks/useFetch";
import { BodegasContext } from "../../../context";
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";

const searchEmailValidation = yup.object().shape({
  email: yup.string().email().required(),
});

export default function ModalFormAdd({ show, setShow, getMore }) {
  const { fletesAPI } = useFetch();
  const { companyId } = useContext(BodegasContext);
  const registerMember = async (data, formData) => {
    try {
      fletesAPI.post(`api/company/${companyId}/members/`, {
        member_id: data.id,
        role: formData.role,
      });
      const time = setTimeout(() => {
        getMore();
        setShow(false);
        toast.success(mensajesAPI("toast_success_user_added_to_company"));
        reset();
      }, 50);
      return () => clearTimeout(time);
    } catch (e) {
      if (e.request.status == 500) {
        toast.error(mensajesAPI("toast_generic_error"));
      } else {
        toast.error(erroresCodes(e.response.data.error_code));
      }
      throw e;
    }
  };
  const searchEmail = async (data) => {
    await fletesAPI
      .get(`api/users/search/`, {
        params: {
          email: data.email,
        },
      })
      .then((e) => {
        registerMember(e.data, data);
      })
      .catch((e) => {
        if (e.request.status == 500) {
          toast.error(mensajesAPI("toast_generic_error"));
        } else if (e.request.status == 404) {
          toast.error(mensajesAPI("user_not_found"));
        } else {
          toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(searchEmailValidation),
  });
  const onSubmit = (data) => {
    searchEmail(data);
  };
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Agregar un nuevo miembro</h5>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control
                type="text"
                placeholder="Correo electrónico"
                {...register("email")}
                isInvalid={errors.email}
              />
              {errors.email && (
                <p className="dashboard_form_error_message">
                  {mensajesFormulario("email_required")}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRoleSelect">
              <Form.Label>Rol</Form.Label>
              <Form.Select {...register("role")}>
                <option value="1">Administrador</option>
                <option value="2">Conductor</option>
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" size="sm" type="submit">
              Agregar
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShow(false);
                reset();
              }}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

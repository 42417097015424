import axios from 'axios';
import { useContext } from "react"
import { BodegasContext } from '../context';


const useFetch = () => {
  const { session } = useContext(BodegasContext)

  const fletesAPI = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: { 'Content-Type': 'application/json', AUTHORIZATION: session },
  })

  const fletesAPIFile = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {'Content-Type': 'multipart/form-data', AUTHORIZATION: session },
  })

  const fletesAPINotURL = axios.create({
    baseURL: '',
    headers: { 'Content-Type': 'application/json', AUTHORIZATION: session },
  })
  return {
    fletesAPI, 
    fletesAPINotURL,
    fletesAPIFile
  }
}


export default useFetch;
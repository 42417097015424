import { useState, useEffect } from "react";
import {
  Row,
  Container,
  Table,
  Spinner,
  Pagination,
} from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import { MdEdit, MdPayments, MdPeople } from "react-icons/md";
import ModalEditCompany from "./AdminCompanies/ModalEditCompany";
import ModalEditCompanyContactInformation from "./AdminCompanies/ModalEditCompanyContactInformation";
import toast from "react-hot-toast";
import { VscWorkspaceTrusted, VscWorkspaceUntrusted } from "react-icons/vsc";
import RenderButtonMore from "../../fyb_components/results/RenderButtonMore";
import RenderURL from "../../fyb_components/results/RenderURL";
import { FaPallet } from "react-icons/fa";
import { AiFillSafetyCertificate } from "react-icons/ai";
import RenderSpace from "../../fyb_components/results/RenderSpace";

export default function Companies() {
  const [showEditCompany, setShowEditCompany] = useState(false);
  const [
    showEditCompanyContactInformation,
    setShowEditCompanyContactInformation,
  ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState(null);
  const [dataUpdate, setDataUpdate] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const [modalUDT, setModalUDT] = useState(false);

  const reloadCompanies = async (e) => {
    setLoading(true);
    const ur = e;
    await fletesAPINotURL
      .get(ur)
      .then((i) => {
        setCompanies(i.data);
        setLoading(false);
      })
      .catch((i) => {
        console.log(i);
      });
  };

  const getCompanies = async () => {
    await fletesAPI
      .get("api/company/")
      .then((e) => {
        setCompanies(e.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateCertification = async (data) => {
    await fletesAPI
      .post("api/company/" + data + "/validate/")
      .then((e) => {
        toast.success("Validado");
        getCompanies();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    showEditCompanyContactInformation
      ? setModalUDT(true)
      : setTimeout(() => {
          setModalUDT(false);
        }, 200);
  }, [showEditCompanyContactInformation]);

  if (!companies) {
    getCompanies();
  }

  return (
    <>
      <Row className="mt-5">
        <h1 className="dashboard_main_container_h1 fyb_h1">
          Administrador Companias
        </h1>
      </Row>
      <Row>
        <Container>
          {loading ? (
            <RenderSpace>
              <Spinner animation="border" variant="primary" />
            </RenderSpace>
          ) : (
            <Table
              striped
              bordered
              responsive
              className="dashboard_main_container_table"
            >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Logística</th>
                  <th>Transportista</th>
                  <th>Redes</th>
                  <th>Dias a pagar</th>
                  <th>Informacion de Contacto</th>
                  <th>Verificado</th>
                  <th>Dueño</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {companies &&
                  companies.results.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e.name}</td>
                        <td>{e.broker ? "Si" : "No"}</td>
                        <td>{e.carrier ? "Si" : "No"}</td>
                        <td
                          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                        >
                          {e.facebook_url && (
                            <RenderURL
                              url={e.facebook_url}
                              name={"Facebook"}
                              color={"#3b5998"}
                            />
                          )}
                          {e.twitter_user && (
                            <RenderURL
                              url={e.twitter_user}
                              name={"Twitter"}
                              color={"#1da1f2"}
                            />
                          )}
                          {e.website_url && (
                            <RenderURL
                              url={e.website_url}
                              name={"Mi Web"}
                              color={"grey"}
                            />
                          )}
                        </td>
                        <td>{e.default_days_to_pay}</td>
                        <td>
                          {e.contact_information.address_line1}
                          <br />
                          {e.contact_information.address_line2}
                          <br />
                          {e.contact_information.zip_code}
                          <br />
                          {e.contact_information.phone}
                          <br />
                          {e.contact_information.city && (
                            <>
                              {e.contact_information.city.name},{" "}
                              {e.contact_information.city.state.name},{" "}
                              {e.contact_information.city.state.country.name}
                            </>
                          )}
                        </td>
                        <td>
                          {e.verified ? (
                            <VscWorkspaceTrusted />
                          ) : (
                            <VscWorkspaceUntrusted />
                          )}{" "}
                          <a href="#" onClick={() => updateCertification(e.id)}>
                            Cambiar
                          </a>
                        </td>
                        <td>
                          {e.owner.email} <br /> {e.owner.profile.first_name}{" "}
                          {e.owner.profile.last_name} <br />{" "}
                          {e.owner.profile.phone}{" "}
                        </td>
                        <td>
                          <RenderButtonMore
                            buttons={[
                              {
                                label: (
                                  <>
                                    Compañia <MdEdit />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.id}/edit_company`;
                                },
                              },
                              {
                                label: (
                                  <>
                                    Contacto <MdEdit />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.id}/edit_contact`;
                                },
                              },
                              {
                                label: (
                                  <>
                                  Usuarios <MdPeople />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.id}/users`;
                                }
                              },
                              {
                                label: (
                                  <>
                                  Facturas <MdPayments />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.id}/invoices`;
                                }
                              },
                              {
                                label: (
                                  <>
                                  Cargas <FaPallet />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.id}/orders`;
                                }
                              },
                              {
                                label: (
                                  <>
                                  Certificado <AiFillSafetyCertificate />
                                  </>
                                ),
                                onClick: () => {
                                  window.location.href = `/dashboard/companies/${e.id}/certificated`;
                                }
                              }
                            ]}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </Container>
        {!loading && (
          <Container>
            <Pagination>
              <Pagination.Prev
                disabled={!companies.previous}
                onClick={() => reloadCompanies(companies.previous)}
              />
              <Pagination.Next
                disabled={!companies.next}
                onClick={() => reloadCompanies(companies.next)}
              />
            </Pagination>
          </Container>
        )}
      </Row>
      <ModalEditCompany
        show={showEditCompany}
        setShow={setShowEditCompany}
        getMore={getCompanies}
        dataUpdate={dataUpdate}
      />

      {modalUDT && (
        <ModalEditCompanyContactInformation
          show={showEditCompanyContactInformation}
          setShow={setShowEditCompanyContactInformation}
          getMore={getCompanies}
          dataUpdate={dataUpdate}
        />
      )}
    </>
  );
}

import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useFetch from "../../hooks/useFetch";
import { erroresCodes } from "../../utils";
import { mensajesAPI, mensajesFormulario } from "../../utils/mensajes.js";
import { addNewAttachments } from "../../utils/formsValidation.js";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { colourStyles } from "../../utils/styles";
import {
  freightpostAttachmentOptions,
} from "../../utils/defaultValues";

export default function ModalAddAttachmentWithToken({ show, setShow, getMore, dataFreightPost }) {

  const { fletesAPI, fletesAPIFile } = useFetch()

  const addFreightPostAttachment = async (data) => {
    let formData = new FormData();
    formData.append('token', dataFreightPost.token_for_public_access)
    formData.append('file_name', data.file_name);
    formData.append('type', data.type.value);
    formData.append('file_content', data.file_content[0])
    await fletesAPIFile.post('api/freightpost/' + dataFreightPost.id + '/share/attachments/', formData)
    .then((e) => {
      getMore("init");
      setShow(false);
      toast.success(mensajesAPI("toast_success_saving_message"));
      reset();
    })
    .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error(mensajesAPI("toast_generic_error"));
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
    });
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(addNewAttachments),
  });
  const onSubmit = (data) => {
    addFreightPostAttachment(data);
  };

  return (
    <>
      <Toaster />
      <Modal show={show} onHide={() => {setShow(false); reset()}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <h5 className="fyb_h5 text-center">Agregar un archivo</h5>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="file_name">
                <Form.Label>Nombre del archivo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ejemplo"
                  {...register("file_name")}
                  isInvalid={errors.internal_reference_number}
                />
                { errors.internal_reference_number && <p className="dashboard_form_error_message">{mensajesFormulario("internal_reference_number_required")}</p> }
            </Form.Group>
            <Form.Group controlId="formTipoSelect">
              <Form.Label>Tipo</Form.Label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder="Tipo"
                    {...field}
                    styles={colourStyles}
                    options={freightpostAttachmentOptions}
                    isClearable />
                )}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mt-10">
                <Form.Label>Subir Archivos</Form.Label>
                <Form.Control {...register("file_content")} name="file_content" type="file" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" size="sm" type="submit">
              Subir Archivo
            </Button>
            <Button variant="danger" size="sm" onClick={() => {setShow(false); reset()}}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

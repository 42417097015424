import { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Spinner,
  Button,
  Pagination,
  Badge,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import useFetch from "../../../../hooks/useFetch";
import { statusTaskColor, statusTaskLabel } from "../../../../utils";
import ModalAddTask from "../ModalAddTask";
import ModalDelTask from "../ModalDelTask";
import ModalTask from "../ModalTask";

const Tasks = ({ order, carrier }) => {
  const [tasks, setTasks] = useState(null);
  const [modalAddTask, setModalAddTask] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [modalDelTask, setModalDelTask] = useState(false);
  const [modalTask, setModalTask] = useState(false);
  const [loading, setLoading] = useState(true);
  const { fletesAPI } = useFetch();

  const getTasks = async () => {
    const promise = fletesAPI.get(`api/freight/${order.id}/task/`);

    toast.promise(promise, {
      loading: "Cargando tareas...",
      success: "Tareas cargadas con éxito",
      error: "Error al cargar tareas",
    });

    promise
      .then((response) => {
        setTasks(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reloadTasks = async (url) => {
    const promise = fletesAPI.get(url);

    toast.promise(promise, {
      loading: "Cargando tareas...",
      success: "Tareas cargadas con éxito",
      error: "Error al cargar tareas",
    });

    promise
      .then((response) => {
        setTasks(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hideModalAddTask = () => {
    setModalAddTask(false);
  };

  const showModalAddTask = () => {
    setModalAddTask(true);
  };

  const hideModalDel = () => {
    setModalDelTask(false);
  };

  const showModalDel = (e, task) => {
    e.stopPropagation();
    setCurrentTask(task);
    setModalDelTask(true);
  };

  const hideModalTask = () => {
    setModalTask(false);
  };

  const showModalTask = (task) => {
    setCurrentTask(task);
    setModalTask(true);
  };

  useEffect(() => {
    if (tasks === null) {
      getTasks();
    }
  }, [tasks]);

  return (
    <>
      <Toaster />
      <Row>
        <Col>
          <Card>
            <Card.Header className="d-flex justify-content-between align-item-center">
              <p className="m-0">Tareas</p>
              {carrier && (
                <Button
                  className="text-white"
                  variant="primary"
                  onClick={showModalAddTask}
                >
                  Agregar Tarea
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" role="status" />
                </div>
              ) : (
                <Table
                  responsive
                  hover
                  className="dashboard_main_container_table"
                >
                  <thead>
                    <tr>
                      <th>Titulo</th>
                      <th>Instrucciones</th>
                      <th>Estado</th>
                      {carrier && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {tasks && tasks.results.length > 0 ? (
                      tasks.results.map((task, index) => (
                        <tr key={index} onClick={() => showModalTask(task)}>
                          <td>{task.title}</td>
                          <td>{task.instructions}</td>
                          <td>
                            <Badge bg={statusTaskColor(task.status)}>
                              {statusTaskLabel(task.status)}
                            </Badge>
                          </td>
                          {carrier && (
                            <td>
                              <MdDelete className="text-danger fs-4" onClick={(e) => showModalDel(e, task)}/>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No hay tareas</td>
                      </tr>
                    )}
                  </tbody>
                  {tasks && (
                    <Pagination>
                      <Pagination.Prev
                        disabled={!tasks.previous}
                        onClick={() => reloadTasks(tasks.previous)}
                      />
                      <Pagination.Next
                        disabled={!tasks.next}
                        onClick={() => reloadTasks(tasks.next)}
                      />
                    </Pagination>
                  )}
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ModalDelTask 
        show={modalDelTask}
        hideModal={hideModalDel}
        task={currentTask}
        update={getTasks}
      />
      <ModalAddTask
        id={order.id}
        handleClose={hideModalAddTask}
        open={modalAddTask}
        update={getTasks}
      />
      <ModalTask
        open={modalTask}
        handleClose={hideModalTask}
        task={currentTask}
        update={getTasks}
        carrier={carrier}
      />
    </>
  );
};

export default Tasks;

import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import FybNavigationBarDashboard from "../components/Dashboard/FybNavigationBarDashboard";
import { useParams } from "react-router-dom";

import ContDashboard from "../components/Dashboard/ContDashboard";
import DashboardSearch from "../components/Dashboard/Dashboard";
import SearchTrucks from "../components/Dashboard/SearchTrucks";
import Orders from "../components/Dashboard/Orders";
import MyOrderDetail from "../components/Dashboard/OrderDetail/MyOrderDetail";
import Trucks from "../components/Dashboard/Trucks";
import Profile from "../components/Dashboard/Profile";
import Companies from "../components/Dashboard/Companies";
import Warehouses from "../components/Dashboard/Warehouses";
import WarehousesId from "../components/Dashboard/WarehousesId";
import GasStations from "../components/Dashboard/GasStations";
import Invitations from "../components/Dashboard/Invitations";
import TruckRoutes from "../components/Dashboard/TruckRoutes/";
import MyWarehouses from "../components/Dashboard/MyWarehouses";
import MyTrucks from "../components/Dashboard/MyTrucks";
import MyWarehouseById from "../components/Dashboard/MyWarehouseById";
import MyTrucksById from "../components/Dashboard/MyTrucksById";
import CompaniesById from "../components/Dashboard/CompaniesById";
import Certificated from "../components/Dashboard/Certificated";
import AdminUsers from "../components/Dashboard/AdminUsers";
import AdminUsersById from "../components/Dashboard/AdminUsersById";
import AdminOrders from "../components/Dashboard/AdminOrders";
import AdminOrdersById from "../components/Dashboard/AdminOrdersById";
import AdminTrucks from "../components/Dashboard/AdminTrucks";
import AdminRoutes from "../components/Dashboard/AdminRoutes";
import AdminRoutesById from "../components/Dashboard/AdminRoutesById";
import AdminPointofContacts from "../components/Dashboard/AdminPointofContacts";
import AdminPointofContactsById from "../components/Dashboard/AdminPointofContactsById";
import Membership from "../components/Dashboard/Membership";
import AdminInvoices from "../components/Dashboard/AdminInvoices";
import Quotes from "../components/Dashboard/Quotes";
import QuoteById from "../components/Dashboard/QouteById"

import ModalRegisterCompany from "../components/Dashboard/ModalRegisterCompany";
import ModalSelectCompany from "../components/Dashboard/ModalSelectCompany";
import Points from "../components/Dashboard/Points";
import Users from "../components/Dashboard/Users";
import Company from "../components/Dashboard/Company";
import AdminWarehouses from "../components/Dashboard/AdminWarehouses";
import { useSession } from "../hooks";
import useFetch from "../hooks/useFetch";
import OrderEdit from "../components/Dashboard/OrderEdit";
import MyQuotes from "../components/Dashboard/MyQoutes";
import CreateQuote from "../components/Dashboard/CreateCoute";
import GenerateQuote from "../components/Dashboard/GenerateQuote";
import MyOrders from "../components/Dashboard/MyOrders";
import MyOrderById from "../components/Dashboard/MyOrderById";
import { BodegasContext } from "../context";

export default function Dashboard(props) {
  const [navigation, setNavigation] = useState(props.start);
  const [companies, setCompanies] = useState(null);
  const [modalCompany, setModalCompany] = useState(false);
  const [modalSelectCompany, setModalSelectCompany] = useState(false);
  const [mycompany, setMycompany] = useState(null);
  const { carrier } = useContext(BodegasContext);

  const { fletesAPI } = useFetch();
  const updateMyCompany = async () => {
    await fletesAPI
      .get(`api/company/me/`)
      .then((e) => {
        setMycompany(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const { isLoggedIn } = useSession({
    setModalCompany,
    setModalSelectCompany,
    setCompanies,
  });

  const listComponentes = {
    dashboard: "searchLoads",
    orders: "orders",
    myOrders: "my_orders",
    myOrderById: "my_orders_by_id",
    trucks: "trucks",
    contacts: "contacts",
    users: "users",
    invitations: "invitations",
    affiliates: "affiliates",
    profile: "profile",
    company: "company",
    warehouses: "warehouses",
    warehousesId: "warehousesId",
    gasstations: "gasstations",
    companies: "companies",
    companiesById: "companies_id",
    adminwarehouses: "adminwarehouses",
    adminaffiliates: "adminaffiliates",
    searchTrucks: "searchTrucks",
    truckroutes: "truckroutes",
    orderdetail: "orderdetail",
    orderEdit: "orderEdit",
    myWarehouses: "my_warehouses",
    myTrucks: "my_trucks",
    myWarehouseById: "my_warehouses_id",
    myTrucksById: "my_trucks_id",
    adminwarehousesId: "adminwarehouses_id",
    adminUsers: "adminusers",
    adminUsersById: "adminusers_id",
    adminOrders: "adminorders",
    adminOrdersById: "adminorders_id",
    adminTrucks: "admintrucks",
    adminTrucksById: "admintrucks_id",
    adminRoutes: "adminroutes",
    adminRoutesById: "adminroutes_id",
    adminContacts: "admincontacts",
    adminContactsById: "admincontacts_id",
    membership: "membership",
    adminInvoices: "admininvoices",
    certificated: "certificated",
    quote: "quote",
    quotes: "quotes",
    createQuote: "create_quote",
    myQuotes: "my_quotes",
    qouteById: "qoute_by_id",
  };

  if (!isLoggedIn) {
    window.location.href = "/login";
  }

  function navigate_towards(destination) {
    window.history.replaceState(null, "", "/dashboard/" + destination);
    setNavigation(destination);
  }

  useEffect(() => {
    if (mycompany === null) {
      updateMyCompany();
    }
  }, [mycompany]);

  return (
    <>
      <FybNavigationBarDashboard navigate_towards={navigate_towards} mycompany={mycompany} getCompanies={updateMyCompany} />
      <div className="d-flex">
        <ContDashboard>
          <Container
            className="dashboard_main_container"
            id="dashboard_main_container"
          >
            {navigation === listComponentes.orders ? (
              <Orders />
            ) : navigation === listComponentes.myOrders ? (
              <MyOrders carrier={carrier} />
            ) : navigation === listComponentes.myOrderById ? (
              <MyOrderById carrier={carrier} />
            ) : navigation === listComponentes.orderdetail ? (
              <MyOrderDetail />
            ) : navigation === listComponentes.orderEdit ? (
              <OrderEdit />
            ) : navigation === listComponentes.trucks ? (
              <Trucks />
            ) : navigation === listComponentes.searchTrucks ? (
              <SearchTrucks />
            ) : navigation === listComponentes.contacts ? (
              <Points />
            ) : navigation === listComponentes.warehouses ? (
              <Warehouses />
            ) : navigation === listComponentes.warehousesId ? (
              <WarehousesId />
            ) : navigation === listComponentes.myWarehouses ? (
              <MyWarehouses />
            ) : navigation === listComponentes.myWarehouseById ? (
              <MyWarehouseById />
            ) : navigation === listComponentes.membership ? (
              <Membership />
            ) : navigation === listComponentes.certificated ? (
              <Certificated />
            ) : navigation === listComponentes.adminwarehousesId ? (
              <MyWarehouseById />
            ) : navigation === listComponentes.adminUsers ? (
              <AdminUsers />
            ) : navigation === listComponentes.adminUsersById ? (
              <AdminUsersById />
            ) : navigation === listComponentes.adminOrders ? (
              <AdminOrders />
            ) : navigation === listComponentes.adminOrdersById ? (
              <AdminOrdersById />
            ) : navigation === listComponentes.adminTrucks ? (
              <AdminTrucks />
            ) : navigation === listComponentes.adminTrucksById ? (
              <MyTrucksById />
            ) : navigation === listComponentes.adminRoutes ? (
              <AdminRoutes />
            ) : navigation === listComponentes.adminRoutesById ? (
              <AdminRoutesById />
            ) : navigation === listComponentes.adminContacts ? (
              <AdminPointofContacts />
            ) : navigation === listComponentes.adminContactsById ? (
              <AdminPointofContactsById />
            ) : navigation === listComponentes.adminInvoices ? (
              <AdminInvoices />
            ) : navigation === listComponentes.myTrucksById ? (
              <MyTrucksById />
            ) : navigation === listComponentes.myTrucks ? (
              <MyTrucks />
            ) : navigation === listComponentes.gasstations ? (
              <GasStations />
            ) : navigation === listComponentes.users ? (
              <Users />
            ) : navigation === listComponentes.dashboard ? (
              <DashboardSearch />
            ) : navigation === listComponentes.profile ? (
              <Profile />
            ) : navigation === listComponentes.company ? (
              <Company />
            ) : navigation === listComponentes.companies ? (
              <Companies />
            ) : navigation === listComponentes.companiesById ? (
              <CompaniesById />
            ) : navigation === listComponentes.adminwarehouses ? (
              <AdminWarehouses />
            ) : navigation === listComponentes.invitations ? (
              <Invitations />
            ) : navigation === listComponentes.truckroutes ? (
              <TruckRoutes />
            ) : navigation === listComponentes.myQuotes ? (
              <MyQuotes />
            ) : navigation === listComponentes.quote ? (
              <GenerateQuote />
            ) : navigation === listComponentes.quotes ? (
              <Quotes />
            ) : navigation === listComponentes.createQuote ? (
              <CreateQuote />
            ) : navigation === listComponentes.qouteById ? (
              <QuoteById />
            ) : (
              carrier ? <Quotes /> : <GenerateQuote />
            )}
          </Container>
        </ContDashboard>
      </div>
      <ModalRegisterCompany
        show={modalCompany}
        setShow={setModalCompany}
        token={localStorage.getItem("session")}
      />
      <ModalSelectCompany
        show={modalSelectCompany}
        setShow={setModalSelectCompany}
        companies={companies}
      />
    </>
  );
}

import React from "react";
import { Modal, Button } from "react-bootstrap";
import useFetch from '../../../hooks/useFetch'
import toast, { Toaster } from 'react-hot-toast';
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";

export default function ModalDelete({ show, setShow, dataDelete, getMore }) {
  const { fletesAPI } = useFetch()
  const deleteMyTruckRoute = async () => {
    await fletesAPI
      .delete(`api/truckroutes/${dataDelete.id}/`)
      .then(() => {
        setShow(false);
        getMore("init");
        toast.success(mensajesAPI("toast_success_saving_message"));
      })
      .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error(mensajesAPI("toast_generic_error"));
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };
  return (
    <>
      <Toaster />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <h6>
          ¿Estás seguro de eliminar esta ruta?
          </h6>
          <p>{dataDelete && `De ${dataDelete.origin_city.name}, ${dataDelete.origin_city.state.name}, ${dataDelete.origin_city.state.country.name} a ${dataDelete.destination_city.name}, ${dataDelete.destination_city.state.name}, ${dataDelete.destination_city.state.country.name}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            size="sm"
            onClick={() => deleteMyTruckRoute()}
          >
            Eliminar
          </Button>
          <Button variant="danger" size="sm" onClick={() => setShow(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
import { useState, useEffect } from "react";
import { Row, Spinner, Container, Pagination, Table } from "react-bootstrap";
import useFetch from "../../../../hooks/useFetch";
import RenderSpace from "../../../../fyb_components/results/RenderSpace";
import { checkMobile } from "../../../../utils";
import RenderButtonMore from "../../../../fyb_components/results/RenderButtonMore";
import RenderPickupDate from "../../../../fyb_components/results/RenderPickupDate";
import RenderCityResult from "../../../../fyb_components/results/RenderCityResult";
import RenderRate from "../../../../fyb_components/results/RenderRate";
import RenderStatus from "../../../../fyb_components/results/RenderStatus";
import { FiFilePlus, FiShare } from "react-icons/fi";
import { MdDelete, MdEdit, MdModeComment } from "react-icons/md";
import { IoTicketSharp } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import ModalDelete from "../../Orders/ModalDelete";

export default function Orders({ dataCompany, getMore }) {
  const [showDelete, setShowDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState(null);
  const [points, setPoints] = useState(null);
  const { fletesAPI, fletesAPINotURL } = useFetch();
  const [isMobile, setIsMobile] = useState(null);

  const reloadOrders = async (e) => {
    let ur;
    switch (e) {
      case null:
        ur = null;
        break;
      case "init":
        ur =
          process.env.REACT_APP_URL_API +
          `api/freightpost/?company_id=${dataCompany.id}`;
        break;
      default:
        ur = e;
        break;
    }
    if (ur != null) {
      setLoading(true);
      await fletesAPINotURL
        .get(ur)
        .then((i) => {
          setOrders(i.data);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          setLoading(false);
        })
        .catch((i) => {
          console.log(i);
          setLoading(false);
        });
    }
  };

  const getPoints = async () => {
    await fletesAPI
      .get(`api/pointofcontact/?company_id=${dataCompany.id}`)
      .then((e) => {
        setPoints(e.data.results);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (!points) {
    getPoints();
  }

  useEffect(() => {
    checkMobile(setIsMobile);
  }, [isMobile]);

  useEffect(() => {
    if (orders == null) {
      reloadOrders("init");
    }
  }, [orders]);

  return (
    <>
      <Container>
        <Row className="d-flex flex-row align-items-center justify-content-between w-100 my-4 mx-0">
          <h1 className="fyb_h1 w-auto">Cargas De Mi Compañia</h1>
        </Row>
        <hr />
        {loading ? (
          <RenderSpace>
            <Spinner animation="border" variant="primary" />
          </RenderSpace>
        ) : orders.results.length > 0 ? (
          <>
            <Table striped bordered className="dashboard_main_container_table">
              <thead>
                <tr>
                  <th>Fecha de Carga</th>
                  <th>Ciudad de origen</th>
                  <th>Ciudad de destino</th>
                  <th>Punto de contacto</th>
                  <th>Numero de referencia</th>
                  <th>Tarifa</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders &&
                  orders.results.map((e, i) => {
                    return (
                      <tr key={i}>
                        <RenderPickupDate
                          date={e.ready_pickup_date}
                          isMobile={isMobile}
                        />
                        <RenderCityResult
                          origin_city={e.origin_city}
                          destination_city={e.destination_city}
                          display_city={e.origin_city}
                          isMobile={isMobile}
                          subtitle={"Origen"}
                        />
                        <RenderCityResult
                          origin_city={e.origin_city}
                          destination_city={e.destination_city}
                          display_city={e.destination_city}
                          isMobile={isMobile}
                          subtitle={"Destino"}
                        />
                        <td>
                          {e.point_of_contact.first_name}{" "}
                          {e.point_of_contact.last_name}
                        </td>
                        <td>{e.internal_reference_number}</td>
                        <RenderRate
                          rate={e.rate}
                          isMobile={isMobile}
                          currency={e.currency}
                        />
                        <RenderStatus isMobile={isMobile} status={e.status} />
                        <td>
                          <RenderButtonMore
                            buttons={[
                              {
                                label: (
                                  <>
                                    Ver archivos <FiFilePlus />
                                  </>
                                ),
                                onClick: () =>
                                  (window.location.href = `/dashboard/order/${e.id}/files`),
                              },
                              {
                                label: (
                                  <>
                                    Editar <MdEdit />
                                  </>
                                ),
                                onClick: () =>
                                  (window.location.href = `/dashboard/order/${e.id}/public`),
                              },
                              {
                                label: (
                                  <>
                                    Gastos <IoTicketSharp />
                                  </>
                                ),
                                onClick: () =>
                                  (window.location.href = `/dashboard/order/${e.id}/expenses`),
                              },
                              {
                                label: (
                                  <>
                                    Comentarios <MdModeComment />
                                  </>
                                ),
                                onClick: () =>
                                  (window.location.href = `/dashboard/order/${e.id}/comments`),
                              },
                              {
                                label: (
                                  <>
                                    GPS <FaMapMarkerAlt />
                                  </>
                                ),
                                onClick: () =>
                                  (window.location.href = `/dashboard/order/${e.id}/gps`),
                              },
                              {
                                label: (
                                  <>
                                    Compartir <FiShare />
                                  </>
                                ),
                                onClick: () =>
                                  (window.location.href = `/dashboard/order/${e.id}/files`),
                              },
                              {
                                label: (
                                  <>
                                    Eliminar <MdDelete />
                                  </>
                                ),
                                onClick: () => {
                                  setShowDelete(true);
                                  setDataDelete(e);
                                },
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {orders && (
              <Pagination>
                <Pagination.Prev
                  disabled={!orders.previous}
                  onClick={() => reloadOrders(orders.previous)}
                />
                <Pagination.Next
                  disabled={!orders.next}
                  onClick={() => reloadOrders(orders.next)}
                />
              </Pagination>
            )}
          </>
        ) : (
          <p>no hay ordenes</p>
        )}
      </Container>
      <ModalDelete
        show={showDelete}
        setShow={setShowDelete}
        dataDelete={dataDelete}
        getMore={reloadOrders}
      />
    </>
  );
}

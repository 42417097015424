import React from "react";
import { Modal, Button } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import toast, { Toaster } from 'react-hot-toast';
import { erroresCodes } from "../../../utils";
import { mensajesAPI, mensajesFormulario } from "../../../utils/mensajes.js";

export default function ModalDelete({ show, setShow, data, getMore }) {
  const { fletesAPI } = useFetch()
  const deletePointOfContact = async () => {
    await fletesAPI
      .delete(`api/pointofcontact/${data.id}/`)
      .then(() => {
        setShow(false);
        getMore("init");
        toast.success(mensajesAPI("point_of_contact_deleted_success"));
      })
      .catch((e) => {
        if(e.request.status == 500)
        {
            toast.error(mensajesAPI("toast_generic_error"));
        }
        else
        {
            toast.error(erroresCodes(e.response.data.error_code));
        }
      });
  };
  return (
    <>
     <Toaster />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <h6>
            ¿Estás seguro de eliminar este punto de contacto?
          </h6> 
          <p>{data && `${data.first_name} ${data.last_name} | ${data.email}`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            size="sm"
            onClick={() => deletePointOfContact()}
          >
            Eliminar
          </Button>
          <Button variant="danger" size="sm" onClick={() => setShow(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import React from "react";
import toast from "react-hot-toast";
import { Modal, Button } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch.js";
import { mensajesAPI } from "../../../utils/mensajes.js";
import { erroresCodes } from "../../../utils/index.js";

const ModalDelTask = ({ show, hideModal, task, update }) => {
  const { fletesAPI } = useFetch();
  const deleteTask = () => {

    const promise = fletesAPI.delete(`api/freight/${task.freight_id}/task/`, {
      data: { task_id: task.id },
    })

    toast.promise(promise, {
      loading: "Eliminando tarea...",
      success: mensajesAPI("toast_success_saving_message"),
      error: (e) => {
        if (e.request.status === 500) {
          return mensajesAPI("toast_generic_error");
        } else {
          return erroresCodes(e.response.data.error_code);
        }
      }
    });

    promise.then(() => {
      hideModal();
      update();
    })
    .catch((e) => {
      console.log(e);
    });
  };

  return (
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <h5 className="fyb_h5 text-center">Borrar tarea</h5>
      </Modal.Header>
      {task && (
        <>
          <Modal.Body>
            <h6>¿Estás seguro de eliminar esta tarea?</h6>
            <p>
              {task.title} : {task.instructions}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success"  size="sm" onClick={() => deleteTask()} >
              Eliminar
            </Button>
            <Button variant="danger" size="sm" onClick={hideModal}>
              Cancelar
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ModalDelTask;

import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import toast from "react-hot-toast"
import useFetch from "../../../../hooks/useFetch";
import ModalAddAppointment from "../ModalAddAppointment";
import ModalUpdateAppointment from "../ModalUpdateAppointment";
import moment from "moment";
import ModalDelete from "../ModalDeleteAppointment";
import { getMonthLength } from "../../../../utils";

const Calendar = ({ trucksData, selected, defaultTitle }) => {
  const [eventList, setEventList] = useState(null);
  const [modalAddAppointment, setModalAddAppointment] = useState(false);
  const [dataAppointment, setDataAppointment] = useState(null);
  const [modalUpdateAppointment, setModalUpdateAppointment] = useState(false);
  const [dataUpdateAppointment, setDataUpdateAppointment] = useState(null);
  const [modalDelAppointment, setModalDelAppointment] = useState(false);
  const [dataDelAppointment, setDataDelAppointment] = useState(null);
  const [displayDate, setDisplayDate] = useState({
    start: new Date(),
    end: new Date(),
  });

  const { fletesAPI } = useFetch();

  const handleDatesSet = (dateInfo) => {
    setDisplayDate({
      start: dateInfo.start,
      end: dateInfo.end,
    });
  };

  const handleCloseAddAppointment = () => {
    setModalAddAppointment(false);
    setDataAppointment(null);
  };

  const handleCloseUpdateAppointment = () => {
    setModalUpdateAppointment(false);
    setDataUpdateAppointment(null);
  };

  const handleCloseDelAppointment = () => {
    setModalDelAppointment(false);
    setDataDelAppointment(null);
  };

  const onPressDelete = ({ data }) => {
    setDataDelAppointment(data);
    setModalDelAppointment(true);
  };

  const onClick = ({ id }) => {
    const event = eventList.find((e) => e.id === id);
    setDataUpdateAppointment(event);
    setModalUpdateAppointment(true);
  }

  const getEvents = async () => {
    try {
      const { data } = await fletesAPI.get(
        `api/companytruck/${trucksData.id}/appointments`, {
          params: {
            start_date: moment(displayDate.start).utc().format(),
            end_date: moment(displayDate.end).utc().format(),
          },
        }
      );
      setEventList(
        data.results.map((e) => {
          return {
            id: e.id,
            title: `${e.title} | \n${e.description}`,
            start: e.start_date,
            end: e.end_date,
            data: e
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const updatedEvent = async ({ id, start, end }) => {
    try {
      await fletesAPI.patch(`api/companytruck/${trucksData.id}/appointments/`, {
        title: eventList.find((e) => e.id === id).data.title,
        description: eventList.find((e) => e.id === id).data.description,
        type: eventList.find((e) => e.id === id).data.type,
        appointment_id: id,
        start_date: moment(start).utc().format(),
        end_date: moment(end).utc().format(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEventChange = (e) => {
    console.log({event: e});
    updatedEvent({ id: e.event.id, start: e.event.start, end: e.event.end});
    const updatedEvents = eventList.map((event) =>
      event.id === e.event.id
        ? { ...event, start: e.event.start, end: e.event.end, title: e.event.title}
        : event
    );
    setEventList(updatedEvents);
  };

  const onSelectEvent = (e) => {
    setDataAppointment(e);
    setModalAddAppointment(true);
  };

  useEffect(() => {
    if (trucksData !== null) {
      toast.promise(getEvents(), {
        pending: "Cargando...",
        success: "Eventos cargados",
        error: "Error al cargar eventos",
      }); 
    }
  }, [ trucksData]);

  useEffect(() => {
    if (displayDate.start !== null && displayDate.end !== null) {
      toast.promise(getEvents(), {
        pending: "Cargando...",
        success: "Eventos cargados",
        error: "Error al cargar eventos",
      });
    }
  }, [displayDate]);


  return (
    <div>
      {eventList !== null ? (
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
          ]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          editable={true}
          select={onSelectEvent}
          events={eventList}
          initialView="timeGridWeek"
          initialDate={new Date()}
          eventContent={(e) => renderEventContent(e, onClick)}
          selectable={true}
          eventChange={handleEventChange}
          datesSet={handleDatesSet}
        />
      ) : (
        <p>Cargando...</p>
      )}
      <ModalAddAppointment 
        show={modalAddAppointment}
        handleClose={handleCloseAddAppointment}
        truckData={dataAppointment}
        update={getEvents}
        id={trucksData?.id}
        selected={selected}
        defaultTitle={defaultTitle}
      />
      <ModalUpdateAppointment
        show={modalUpdateAppointment}
        handleClose={handleCloseUpdateAppointment}
        truckData={dataUpdateAppointment}
        update={getEvents}
        id={trucksData?.id}
        onDelete={onPressDelete}
      />
      <ModalDelete
        show={modalDelAppointment}
        handleClose={handleCloseDelAppointment}
        dataDelete={dataDelAppointment}
        update={getEvents}
        id={trucksData?.id}
      />
    </div>
  );
};

function renderEventContent(eventInfo, onClick) {
  return (
    <div style={{ width: "100%", height: "100%" }} onClick={() => onClick({ id: eventInfo.event.id })}>
      <i>{eventInfo.event.title}</i>
      <p>{eventInfo.event.description}</p>
    </div>
  );
}

export default Calendar;

import { useState, useEffect, useCallback } from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import Select from "react-select";

import { citiesStyles } from "../../utils/styles";

const SelectDriver = ({
  name,
  control,
  defaultValue = undefined,
  placeholder,
  error,
  label,
  companyId,
}) => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState(null);
  const { fletesAPI } = useFetch();

  const fetchDrivers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fletesAPI.get(`api/company/${companyId}/members/drivers/`);
      setDrivers(response.data.map(e => ({ value: e.user.id, label: `${e.user.profile.first_name} ${e.user.profile.last_name}` })));
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  }, [fletesAPI]);

  useEffect(() => {
    if (drivers === null) fetchDrivers();
  }, []);

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || undefined}
        render={({ field }) => (
          <Select
            isClearable
            isLoading={loading}
            styles={citiesStyles}
            placeholder={placeholder}
            onChange={field.onChange}
            value={field.value}
            options={drivers ? drivers : []}
            className={error ? "invalidSelect" : ""}
            onFocus={() => fetchDrivers()}
          />
        )}
      />
      {error && <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>}
    </Form.Group>
  );
};

export default SelectDriver;
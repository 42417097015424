import { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import RenderMenu from "../../../fyb_components/results/RenderMenu";
import RenderSpace from "../../../fyb_components/results/RenderSpace";
import { useParams, useNavigate } from "react-router-dom";
import EditCompany from "./views/EditCompany";
import EditContact from "./views/EditContact";
import Users from "./views/Users";
import useFetch from "../../../hooks/useFetch";
import Invoices from "./views/Invoices";
import Orders from "./views/Orders";
import Certificated from "./views/Certificated";


const viewList = {
  editCompany: "edit_company",  
  editContact: "edit_contact",
  details: "details",
  users: "users",
  invoices: "invoices",
  orders: "orders",
  certificated: "certificated",
};

const CompaniesById = () => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { fletesAPI } = useFetch();

  const getCompany = async () => {
    setLoading(true);
    await fletesAPI
      .get(`api/company/${id}/`)
      .then((e) => {
        setCompany(e.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (company === null && id !== undefined && type !== undefined) {
      getCompany();
    }
  }, [company]);

  if (loading) {
    return (
      <RenderSpace>
        <Spinner animation="border" variant="primary" />
      </RenderSpace>
    );
  }
  return(
    <>
      <Container>
        <hr />
        <Row>
          <Col xl={2} lg={2} md={3} sm={4}>
            <RenderMenu
              options={[
                {
                  label: "Editar Compañía",
                  active: type === viewList.editCompany,
                  onClick: () => {
                    navigate(`/dashboard/companies/${id}/${viewList.editCompany}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Editar Contacto",
                  active: type === viewList.editContact,
                  onClick: () => {
                    navigate(`/dashboard/companies/${id}/${viewList.editContact}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Usuarios",
                  active: type === viewList.users,
                  onClick: () => {
                    navigate(`/dashboard/companies/${id}/${viewList.users}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Facturas",
                  active: type === viewList.invoices,
                  onClick: () => {
                    navigate(`/dashboard/companies/${id}/${viewList.invoices}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Cargas",
                  active: type === viewList.orders,
                  onClick: () => {
                    navigate(`/dashboard/companies/${id}/${viewList.orders}`, {
                      replace: true,
                    });
                  },
                },
                {
                  label: "Cetificado",
                  active: type === viewList.certificated,
                  onClick: () => {
                    navigate(`/dashboard/companies/${id}/${viewList.certificated}`, {
                      replace: true,
                    });
                  },
                },
              ]}
            />
          </Col>
          <Col xl={10} lg={10} md={9} sm={8}>
            {loading && <RenderSpace><Spinner animation="border" variant="primary" /></RenderSpace>}
            {!loading && type === viewList.editCompany && <EditCompany getMore={getCompany} dataCompany={company} />}
            {!loading && type === viewList.editContact && <EditContact getMore={getCompany} dataCompany={company} />}
            {!loading && type === viewList.users && <Users getMore={getCompany} dataCompany={company} />}
            {!loading && type === viewList.invoices && <Invoices getMore={getCompany} dataCompany={company} />}
            {!loading && type === viewList.orders && <Orders getMore={getCompany} dataCompany={company} />}
            {!loading && type === viewList.certificated && <Certificated dataCompany={company} />}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CompaniesById;